.banve-container {
  min-height: 100vh;
  background-color: $main-background-color;

  .panel-wrapper {
    background-color: $white;
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);

    .panel-wrapper__title {
      padding: 0.5rem 1rem;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid $border-color;
    }

    .panel-wrapper__body {
      padding: 0.5rem 1rem;
    }
  }

  .sellticket__actions__select {
    & > div {
      min-height: 42px;
    }
  }

  table.tbl-invoice-items {
    width: 100%;

    tr td,
    tr th {
      padding: 0.2rem;
    }
  }

  table.table-amount {
    width: 100%;
    background-color: $gray-100;

    tr {
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: none;
        color: $danger;
      }

      td {
        padding: 0.5rem 1rem;
        font-size: 18px;
        font-weight: 700;

        &:last-child {
          min-width: 90px;
          text-align: right;
        }
      }
    }
  }
}
