.chonve {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .chonve__list {
    flex: 1;

    .chonve__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $gray-200;
      border-radius: 5px;

      &.active {
        background-color: $secondary-light;
        .chonve__item-check {
          color: $secondary;
        }
      }

      .chonve__item-name {
        font-weight: 700;
      }

      .chonve__item-price {
        font-size: 14px;

        .chonve__item-price__price {
          color: $red;
          font-weight: 700;
        }

        .chonve__item-price__unit {
          font-style: italic;
        }
      }
    }
  }
}
