.form-option-react-select {
  & > div {
    min-height: 43px;
  }

  .css-1s2u09g-control {
    border-color: $input-border-color;
  }

  .css-319lph-ValueContainer {
    * {
      font-size: 14px !important;
      color: $input-color !important;
    }
  }
}
