.contacts {
  .contacts__table {
    flex: 1;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .contacts__actions {
    position: fixed;
    right: 1rem;
    bottom: 1rem;

    .contacts__actions__buttons {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .btn-add-contact {
        border-radius: 100px;
        width: 60px;
        height: 60px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .ni {
          font-size: 40px;
        }
      }
    }
  }
}

.store-contacts {
  .store-contacts__buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $white;
    padding: 0.7rem 0;
  }
}
