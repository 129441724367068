.userprofile {
  background-color: $main-background-color;
  max-height: calc(100vh - 110px);
}

.userprofile__info {
  background-color: $white;
}

.userprofile__rowinfo {
  display: flex;
  border-bottom: 1px solid $gray-200;
  padding: 4px 0;
  font-size: 14px;

  & > div:first-child {
    width: 80px;
  }

  & > div:last-child {
    font-weight: 700;
  }

  &:last-child {
    border: none;
  }
}

.userprofile__actions {
  .userprofile__actions__btn {
    background-color: $white;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}
