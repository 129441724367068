.box-shadow {
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

// =================================
// SELECTS =========================
// =================================

.select-loaive {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // background-color: $gray-200;
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid $gray-400;

  .select-loaive__value-content {
    flex: 1;
    text-align: center;
    font-weight: 700;
    padding: 0 8px;

    .select-loaive__value-content__title {
      font-size: 12px;
    }

    .select-loaive__value-content__values {
      font-size: 14px;
      color: $secondary;
    }
  }
}

.select-date {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: $gray-200;
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid $gray-400;

  .select-date__value-content {
    flex: 1;
    text-align: center;
    font-weight: 700;
    padding: 0 8px;

    .select-date__value-content__title {
      font-size: 12px;
    }

    .select-date__value-content__values {
      font-size: 14px;
      color: $secondary;
    }
  }
}

.daterangeselect {
  display: flex;
  align-items: center;

  .fa {
    padding: 0 4px;
  }

  .rdtPicker {
    width: 150px; //Change it your preferred width
  }

  // .daterangeselect__fromdate {
  //   .rdtPicker {
  //     right: 0px;
  //   }
  // }

  .daterangeselect__todate {
    .rdtPicker {
      right: 0px;
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $white;
  }
}

.reactnative-navigation {
  height: 85px;
}

.rdt.align-right {
  .rdtPicker {
    right: 0px;
  }
}
