.dashboard {
  background-color: #f1f1f1;
  min-height: 100vh;
}

.dashboard__userinfo-container {
  background-color: #fff;
  display: flex;
  align-items: center;
}

.dashboard__userinfo__img-profile {
  padding-right: 15px;

  img {
    width: 40px;
    height: 40px;
  }
}

.dashboard__userinfo__info {
  flex: 1;

  .dashboard__userinfo__info-name {
    font-weight: 700;
  }
  .dashboard__userinfo__info-username {
    font-size: 13px;
  }
}

.dashboard__userinfo__logout {
  padding-left: 15px;

  img {
    width: 25px;
    height: 25px;
  }
}

.dashboard__chart {
  background-color: #fff;
  border-radius: 10px;

  .dashboard__chart-title {
    font-weight: 700;
    color: $primary;
  }

  .dashboard__chart-content {
    // height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed $gray-300;
  }

  .dashboard__chart-date {
    display: flex;
    justify-content: center;

    span {
      background-color: $gray-300;
      font-size: 10px;
      padding: 4px 8px;
      border-radius: 15px;
      margin: 0 2px;
    }

    span:hover {
      background-color: lighten($primary, $amount: 50);
      cursor: pointer;
    }

    .active {
      background-color: lighten($primary, $amount: 50);
    }
  }
}

.action_buttons {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: row;

  button {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa {
      font-size: 20px;
    }
  }
}
