$filter-height: 205px;

.danhsachve {
  max-height: 100vh;
  background-color: $main-background-color;

  .danhsachve-header {
    height: $mobile-header-height;
    background-color: $main-background-color;
    z-index: 1;

    h5 {
      margin: 0;
    }
  }

  .danhsachve__title {
    font-weight: 700;
    color: $primary;
  }

  .danhsachve__filter {
    background-color: #fff;
    margin-bottom: 1rem;

    .input-group {
      flex-wrap: nowrap;
    }
  }

  .danhsachve__list {
    max-height: calc(100vh - 205px - 1rem);
    overflow-y: scroll;

    .danhsachve__item {
      display: flex;
      background-color: $white;
      padding: 8px 16px;
      border-radius: 10px;
      margin-bottom: 8px;

      & > div:first-child {
        flex: 1;
      }

      & > div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;
        color: $red;
      }

      .danhsachve__item-name {
        font-weight: 700;
      }

      .danhsachve__item-price,
      .danhsachve__item-date {
        font-size: 14px;
      }

      .danhsachve__item-date {
        font-style: italic;
      }

      .danhsachve__item-price {
        .danhsachve__item-price_price {
          color: $red;
          font-weight: 700;
        }
        .danhsachve__item-price_unit {
          font-style: italic;
        }
      }
    }
  }
}
