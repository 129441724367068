.chonloaive {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .chonloaive__list {
    flex: 1;

    .chonloaive__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $gray-200;
      border-radius: 5px;

      &.active {
        background-color: $secondary-light;
        .chonloaive__item-check {
          color: $secondary;
        }
      }
    }
  }
}
