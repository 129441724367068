.login-container {
  display: flex;
  height: 100vh;
  flex-direction: column;

  .img-login-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 300px;
    }
  }

  .form-login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      width: 80%;
      max-width: 400px;
    }
  }

  .forgot-password-container {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-style: italic;
    padding: 15px 0;
  }
}
