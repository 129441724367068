/*!

=========================================================
* Argon Design System React - v1.1.0 based on Argon Design System - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap Functions
@import "../../../node_modules/bootstrap/scss/functions";

// Theme Variables
@import "argon-design-system/variables";

// Bootstrap Core
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Theme Core
@import "argon-design-system/theme";

// React Differences
@import "react/react-differences";

// Views
@import "view/common";
@import "view/login";
@import "view/dashboard";
@import "view/chonloaive";
@import "view/chonve";
@import "view/banve";
@import "view/mymodal";
@import "view/danhsachve";
@import "view/userprofile";
@import "view/banvev2";
@import "view/contacts";
