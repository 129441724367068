$list-item-right-width: 130px;

.sellticket {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .sellticket__list {
    flex: 1;
    padding: 0 15px;

    .sellticket__list-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray-200;
      font-size: 14px;

      .sellticket__list-header-left {
        flex: 1;
      }

      .sellticket__list-header-right {
        width: $list-item-right-width;
      }
    }

    .sellticket__list-content {
      .sellticket__list-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray-200;

        .sellticket__list-item-left {
          flex: 1;
        }

        .sellticket__list-item-right {
          width: $list-item-right-width;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .sellticket__list-item-name {
          font-weight: 700;
        }

        .sellticket__list-item-price {
          font-size: 14px;

          .sellticket__list-item-price-price {
            color: $red;
            font-weight: 700;
          }

          .sellticket__list-item-price-unit {
            font-style: italic;
          }
        }

        .sellticket__list-item-trash {
          color: $red;
          font-size: 20px;
          padding-left: 16px;
        }
      }
    }

    .sellticket__list-total {
      display: flex;
      justify-content: space-between;
      font-weight: 700;

      .sellticket__list-total-left {
        flex: 1;
        color: $red;
      }

      .sellticket__list-total-right {
        width: $list-item-right-width;
        color: $green;
        text-align: right;
      }
    }
  }

  .sellticket__actions {
    border-top: 10px solid $gray-200;
    padding: 0 15px;

    .sellticket__actions__select {
      .css-1s2u09g-control {
        border: 1px solid $gray-200;
        border-radius: 5px;
      }

      .css-qc6sy-singleValue {
        color: $gray-700;
      }
    }

    .sellticket__select-hinhthuc {
      padding: 15px 0;
      border: 1px solid $gray-200;
      border-radius: 5px;
      font-size: 14px;

      .sellticket__select-hinhthuc__title {
        text-align: center;
        font-weight: 700;
        margin-bottom: 8px;
      }

      .sellticket__actions__btns {
        display: flex;
        justify-content: center;
        align-items: center;

        .sellticket__actions__btn {
          padding: 8px 12px;
          background-color: $gray-200;
          width: 130px;
          border-radius: 5px;

          &:first-child {
            margin-right: 12px;
          }

          &.active {
            background-color: $secondary-light;

            .fa {
              color: $secondary;
            }
          }
        }
      }
    }
  }

  .sel_customer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;

    .sel_customer__input {
      flex: 1;
    }

    .sel_customer__camera {
      width: 38px;
      height: 38px;
      border: 1px solid $gray-200;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
    }
  }
}

.inputnum {
  display: flex;

  .inputnum__input {
    width: 40px;
    height: 25px;
    text-align: center;
    border-radius: 0px;
    border: 1px solid $gray-200;
    color: $secondary;
  }

  .inputnum__btn {
    background-color: $gray-100;
    border: 1px solid $gray-200;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modalresult {
  .modalresult__icon {
    font-size: 60px;
  }

  .modalresult__icon-success {
    color: $success;
  }

  .modalresult__icon-danger {
    color: $danger;
  }

  .modalresult__icon-warning {
    color: $warning;
  }

  .modalresult__icon-info {
    color: $info;
  }
}

.banve--tablet {
  display: flex;
  flex-direction: row;

  .banve_col--tablet {
    flex: 1;
    border-right: 2px solid #f1f1f1;

    &:last-child {
      border: none;
    }
  }
}
